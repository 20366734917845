import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Vant from 'vant';

import './reset.css'
import 'lib-flexible/flexible'
import 'vant/lib/index.css';

Vue.config.productionTip = false

Vue.prototype.$axios = axios

Vue.use(Vant)

new Vue({
    el: '#app',
    router,
    render: h => h(App),
})
