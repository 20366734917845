import Vue from 'vue'
import Router from 'vue-router'
import { LoadingBar } from 'view-design'
import { getDocumentTitle } from '../utils'

Vue.use(Router)

// const subfix = '/:bundleId/:osType/:appVersion/:deviceId/:userId/:token'

const routes = [
    // {
    //     path: '/',
    //     meta: { title: '即刻约' },
    //     component: () =>
    //         import ('../views/www/www1.vue')
    // },
    {
        path: '/mobile/home',
        meta: { title: '喂喂' },
        component: () =>
            import ('../views/www/wwwMobile.vue')
    },
    {
        path: '/home/mangqu',
        meta: { title: '盲趣' },
        component: () =>
            import ('../views/www/wwwMangqu.vue')
    },
    {
        path: '/gift/list',
        meta: { title: '我收到的礼物' },
        component: () =>
            import ('../views/gift/giftList.vue'),
    },
    {
        path: '/agreement',
        meta: { title: '用户协议及隐私条款' },
        component: () =>
            import ('../views/agreement/agreement.vue'),
    },
    {
        path: '/agreement/:bundle',
        meta: { title: '用户协议及隐私条款' },
        component: () =>
            import ('../views/agreement/agreement.vue'),
    },
    {
        path: '/videoCallAgreement',
        meta: { title: '视频通话协议' },
        component: () =>
            import ('../views/agreement/videoCall.vue'),
    },
    {
        path: '/riskAgreement',
        meta: { title: '风险告知协议' },
        component: () =>
            import ('../views/agreement/risk.vue'),
    },
    {
        path: '/call/record',
        meta: { title: '通话记录' },
        component: () =>
            import ('../views/call/callRecord.vue'),
    },
    {
        path: '/income/myIncome',
        meta: { title: '我的收益' },
        component: () =>
            import ('../views/income/income.vue'),
    },
    {
        path: '/income/sellerIncome',
        meta: { title: '我的账户' },
        component: () =>
            import ('../views/income/sellerIncome.vue'),
    },
    {
        path: '/withdraw/record',
        meta: { title: '我的收益' },
        component: () =>
            import ('../views/withdraw/withdrawRecord.vue'),
    },
    {
        path: '/withdraw/apply',
        meta: { title: '我的收益' },
        component: () =>
            import ('../views/withdraw/apply.vue'),
    },
    {
        path: '/withdraw/bindAccount',
        meta: { title: '我的收益' },
        component: () =>
            import ('../views/withdraw/bindAccount.vue'),
    },
    {
        path: '/recharge',
        meta: { title: '充值' },
        component: () =>
            import ('../views/recharge/recharge.vue'),
    },
    {
        path: '/justRecharge',
        meta: { title: '充值' },
        component: () =>
            import ('../views/recharge/justRecharge.vue'),
    },
    {
        path: '/wechatRecharge',
        meta: { title: '充值' },
        component: () =>
            import ('../views/recharge/wechatRecharge.vue'),
    },
    {
        path: '/recharge/result',
        meta: { title: '充值结果' },
        component: () =>
            import ('../views/recharge/result.vue'),
    },
    {
        path: '/openapp/recharge/result',
        meta: { title: '充值结果' },
        component: () =>
            import ('../views/recharge/result.vue'),
    },
    {
        path: '/convention/:bundle',
        meta: { title: '文明公约' },
        component: () =>
            import ('../views/convention/convention.vue'),
    },
    {
        path: '/inviteEarnGold/rule',
        meta: { title: '活动规则' },
        component: () =>
            import ('../views/inviteEarnGold/rule.vue'),
    },
    {
        path: '/inviteEarnGold/qrCode',
        meta: { title: '我的二维码' },
        component: () =>
            import ('../views/inviteEarnGold/myQRCode.vue'),
    },
    {
        path: '/inviteEarnGold/invite',
        meta: { title: '邀请加入' },
        component: () =>
            import ('../views/inviteEarnGold/invite.vue'),
    },
    {
        path: '/performance',
        meta: { title: '业绩报告' },
        component: () =>
            import ('../views/performance/performance.vue'),
    },
    {
        path: '/inviteJoinActive',
        meta: { title: '邀请参加' },
        component: () =>
            import ('../views/performance/inviteJoin.vue'),
    },
    {
        path: '/userSummarized',
        meta: { title: '用户资料概述' },
        component: () =>
            import ('../views/user/summarized.vue'),
    },
    {
        path: '/closeSpecialTimeSwitch',
        meta: { title: '恢复充值' },
        component: () =>
            import ('../views/app/closeSpecialTimeSwitch.vue'),
    },
    {
        path: '/feishu',
        meta: { title: '飞书操作' },
        component: () =>
            import ('../views/app/feishu.vue'),
    },
    {
        path: '/',
        meta: { title: '购买vip' },
        component: () =>
            import ('../views/www/buyVip.vue'),
    },
    {
        path: '/accountLog',
        meta: { title: '账单记录' },
        component: () =>
            import ('../views/user/bill.vue'),
    },
    {
        path: '/privacyTerms/:bundle',
        meta: {title: '隐私政策'},
        component: () => import('../views/agreement/privacyTerms.vue'),
    },
    {
        path: '/userAgreement/:bundle',
        meta: {title: '用户协议'},
        component: () => import('../views/agreement/userAgreement.vue'),
    },
    {
        path: '/spider/newTask',
        meta: {title: '新任务'},
        component: () => import('../views/spider/newTask.vue'),
    },
    {
        path: '/spider/newTaskV1',
        meta: {title: '新任务'},
        component: () => import('../views/spider/newTaskV1.vue'),
    },
    {
        path: '/spider/newSearchTask',
        meta: {title: '新任务'},
        component: () => import('../views/spider/newSearchTask.vue'),
    }
]

const router = new Router({
    mode: 'history',
    routes,
})

router.beforeEach(async(to, from, next) => {
    document.title = getDocumentTitle(to.meta.title)
    LoadingBar.start()
    next()
})

router.afterEach(() => {
    LoadingBar.finish()
})

export default router